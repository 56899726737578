import { StoredFileContent } from '@/src/components/ExpandedFdoc/StoredFileContent';
import useDownloadFdoc from '@/src/hooks/useDownloadFdoc';
import { PDFDropdownItems } from '@/src/modules/resource-detail/components/ExpandedResource/Content/StoredFile/PDFDropdownItems';
import { ExpandedResourceDropdownMenu } from '@/src/modules/resource-detail/components/ExpandedResource/components/ExpandedResourceDropdownMenu';
import { HeaderWrapper } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalResourceHeader';
import { ExpandedResourceTitle } from '@/src/modules/resource-detail/components/ExpandedResource/components/ResourceTitle';
import { useResourceStoredMetadata } from '@/src/modules/resources/hooks/useResourceStoredMetadata';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import { renderCssBasedOnColorScheme } from '@/src/modules/ui/theme/renderCssBasedOnColorScheme';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { StoredFileFdoc } from '@/src/types/api';
import React from 'react';
import styled, { css } from 'styled-components';

const StoredFileContentAdapter = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 1;
  overflow: hidden;
  & > * {
    flex-grow: 1;
    height: 1px;
    video {
      height: 100%;
      width: 100%;
      border-radius: 0;

      ${renderCssBasedOnColorScheme({
        light: css`
          background: ${cssVar['color-bg-tertiary']};
        `,
        dark: css`
          background: ${cssVar['color-bg-primary']};
        `,
      })}
    }
  }
`;

export const ResourceContentStoredFile: React.FC<{
  resource: StoredFileFdoc;
}> = ({ resource }) => {
  const imgRef = React.useRef<HTMLImageElement | null>(null);
  const downloadFdoc = useDownloadFdoc();

  const { isTitleReadOnly, isPDF } = useResourceStoredMetadata(resource);

  return (
    <>
      <HeaderWrapper>
        <HeaderWrapper.Left resource={resource}>
          {!isTitleReadOnly ? <ExpandedResourceTitle resource={resource} /> : <div />}
        </HeaderWrapper.Left>
        <HeaderWrapper.Right>
          <ButtonIconDownload
            onClick={() => {
              downloadFdoc(resource);
            }}
            tabIndex={4}
          />
          <ExpandedResourceDropdownMenu resource={resource}>
            {isPDF && <PDFDropdownItems />}
          </ExpandedResourceDropdownMenu>
        </HeaderWrapper.Right>
      </HeaderWrapper>
      <StoredFileContentAdapter>
        <StoredFileContent
          fdoc={resource}
          isFullscreenEnabled={false}
          imageRef={imgRef}
          tinyPagesIsVisible={false}
          disableGestures={false}
        />
      </StoredFileContentAdapter>
    </>
  );
};
